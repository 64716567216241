<template>
    <!-- 摄影师路由 -->
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    
}
</script>
